import React from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import './monitoring/appMonitoring';

import App from 'app/App';
import { AppProviders } from 'app/providers/AppProviders';

const target = document.getElementById('root');

ReactGA.initialize(process.env.REACT_APP_GA || '');

render(
  <AppProviders>
    <App />
  </AppProviders>,

  target
);

// Note: switching serviceWorker.unregister() to serviceWorker.register()
// will opt you in to using the service worker.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
