export const INVLabels = {
  INVOICE_TYPE: 'Invoice type',
  CLOSING_INVOICE: 'Submit your invoice',
  BREAKDOWN:
    'Incorporate breakdown of payout if applicable. Please specify payout percentage for each Registered Representative (please note each Registered Representative must be registered in appropriate States prior to payout).',
  BREAKDOWN_QUESTION: 'Breakdown of payout',
  FINAL_INVOICE_QUESTION: 'Is this the final invoice for this deal?',
};

export const INVOICE_TYPE_OPTIONS = [
  {
    label: 'Closing invoice',
    value: 'Closing invoice',
  },
  {
    label: 'Retainer invoice',
    value: 'Retainer invoice',
  },
];

export const FINAL_INVOICE_OPTIONS = [
  {
    label: 'Yes, the deal has been finalized; please proceed with closing.',
    value: true,
  },
  {
    label: 'No, further actions are being anticipated and additional invoices may follow.',
    value: false,
  },
];
