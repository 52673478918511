import * as yup from 'yup';
import validateFileExtension, {
  basicRequiredInputValidation,
  REQUIRED_MESSAGE,
  validateFileName,
} from '../../../../utils/helpers/formValidators';
import { INVOICE_TYPE_OPTIONS } from './InvoiceFormConstants';

export const validationSchema = yup.object().shape({
  invoiceType: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .required(REQUIRED_MESSAGE),
  invoiceFiles: yup
    .array()
    .required(REQUIRED_MESSAGE)
    .test('', 'Some file has an invalid name', validateFileName)
    .test('', 'Some file has an invalid extension', validateFileExtension),
  breakdown: basicRequiredInputValidation(),
  finalInvoice: yup.boolean().when('invoiceType', {
    is: invoiceType => invoiceType && invoiceType.value === INVOICE_TYPE_OPTIONS[0].value,
    then: yup.boolean().required(REQUIRED_MESSAGE),
    otherwise: yup.boolean().notRequired(),
  }),
});
