// routes
import {
  BagIcon,
  ChatConversationIcon,
  ClipboardIcon,
  DirectoryIcon,
  HomeIcon,
  IncreaseMoneyIcon,
  JewelIcon,
  MegaphoneIcon,
  QuestionMarkIcon,
  ThunderIcon,
  TicketIcon,
  UserIcon,
} from 'app/common/icons';
import { GrowthGraphIcon } from 'app/common/icons/GrowthGraphIcon';
import { FormsU4Context } from 'app/compliance/context/FormU4Context';
import { ERoutePatterns, PlatformRole } from 'app/core-tools/due-diligence/types/types';
import { EFeatureFlag } from 'app/FeatureFlag';
import { ConversationsContext } from 'app/marketplace/context/ConversationsContext';
import { ICollaboration } from 'app/marketplace/types';

// store
import { UserContext } from 'app/users/context/UserContext';
import { DEAL_FLOW_ONLY_ROLES } from 'app/utils/constants/app/appConstants';
import { useFeatureFlags, usePlatformRole } from 'app/utils/hooks/hooks';
import useStateModal from 'app/utils/hooks/useStateModal';
import React, { useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import DealContentSidebar from '../GlobalSidebar/components/DealContentSidebar/DealContentSidebar';
import SettingsModal from '../GlobalSidebar/components/SettingsModal/SettingsModal';
import { Sidebar, SidebarItems, SidebarLinks } from './Sidebar';
import { MainContainer } from './StyledSidebar';
import get from 'lodash/get';
import { analyticsEvents } from 'app/analytics/useAnalyticsEvents';
import { ACTION, EVENT_NAME } from 'app/analytics/constants';
import { useRedirectionState } from 'app/utils/hooks/useRedirectionState';

/**
 * App main sidebar, where the context switcher and deal content is displayed.
 */
const SidebarContainer = () => {
  const user = useContext(UserContext);
  const { redirecting: isRedirecting } = useRedirectionState();

  const location = useLocation();
  const { messageAmountInMyOfferings, messageAmountInMyCollaborations } = useContext(
    ConversationsContext
  );
  const { blockHub, loadingU4 } = useContext(FormsU4Context);

  const hasConversations =
    messageAmountInMyOfferings.data.some(
      (offering: ICollaboration) => offering.newMessagesAmount > 0
    ) ||
    messageAmountInMyCollaborations.data.some(
      (offering: ICollaboration) => offering.newMessagesAmount > 0
    );
  const isCustomer = user && user.isCustomer;
  const isDealFlowLaunchUser = usePlatformRole(PlatformRole.DEAL_FLOW_LAUNCH);
  const isMarketplaceOnlyUser = usePlatformRole(PlatformRole.MARKETPLACE_ONLY_USER);
  const isHubUser = usePlatformRole(PlatformRole.USER);
  const MyAccountFF = useFeatureFlags(EFeatureFlag.MY_ACCOUNT_SECTION);
  const personalizedDashboardFF = useFeatureFlags(EFeatureFlag.WF_PERSONALIZED_DASHBOARDS);
  const MyDealsListV2FF = useFeatureFlags(EFeatureFlag.MY_DEALS_LIST_V2);

  const { show, hide, modalState } = useStateModal('dealSettingModal');
  const { createEvent } = analyticsEvents();

  const sectionsItems: SidebarItems[] = [
    {
      sidebarItems: [
        {
          name: 'Home',
          icon: <HomeIcon width="16px" height="16px" />,
          active: !!useRouteMatch({
            path: ERoutePatterns.HOME,
          }),
          to: ERoutePatterns.HOME,
          id: 'dashboard-item',
          hide: DEAL_FLOW_ONLY_ROLES.includes(user.platformRole),
        },
        {
          name: 'My Deals',
          icon: <TicketIcon width="16px" height="16px" />,
          active: !!useRouteMatch({
            path: MyDealsListV2FF ? ERoutePatterns.DEALS : ERoutePatterns.MY_DEALS,
          }),
          href: ERoutePatterns.DEALS,
          to: ERoutePatterns.MY_DEALS,
          external: !!MyDealsListV2FF,
          id: 'my-deals-item',
          hide:
            blockHub ||
            !user.isCustomer ||
            isMarketplaceOnlyUser ||
            isDealFlowLaunchUser ||
            !personalizedDashboardFF,
        },
        {
          name: 'My Account',
          icon: <UserIcon width="16px" height="16px" />,
          active: !!useRouteMatch({
            path: ERoutePatterns.MY_ACCOUNT,
          }),
          to: ERoutePatterns.MY_ACCOUNT_SETTINGS,
          id: 'my-account-item',
          hide:
            (user.loadingProfileData && !MyAccountFF) ||
            isMarketplaceOnlyUser ||
            isDealFlowLaunchUser,
        },
      ],
    },
    {
      categoryName: isMarketplaceOnlyUser || isDealFlowLaunchUser || !isCustomer ? '' : 'EXECUTE',
      sidebarItems: [
        {
          name: 'Reports',
          icon: <BagIcon width="16px" height="16px" />,
          active: !!useRouteMatch({
            path: [ERoutePatterns.REGULATORY_PROFILE_TRACKER, ERoutePatterns.COMPLIANCE_TRACKER],
          }),
          to: ERoutePatterns.REGULATORY_PROFILE_TRACKER,
          id: 'workflow-forms-item',
          hide: !isCustomer || isMarketplaceOnlyUser || isDealFlowLaunchUser,
        },
        {
          name: 'Document Bank',
          icon: <ClipboardIcon width="16px" height="16px" />,
          active: !!useRouteMatch({ path: ERoutePatterns.DOCUMENT_BANK }),
          to: ERoutePatterns.DOCUMENT_BANK,
          id: 'workflow-document-bank-item',
          hide:
            blockHub ||
            loadingU4 ||
            !isCustomer ||
            isDealFlowLaunchUser ||
            isMarketplaceOnlyUser ||
            user.isImpersonated,
        },
      ],
    },
    {
      categoryName:
        blockHub || isMarketplaceOnlyUser || isDealFlowLaunchUser || !isCustomer ? '' : 'CONNECT',
      sidebarItems: [
        {
          name: 'Marketplace',
          icon: <ThunderIcon width="16px" height="16px" />,
          active: !!useRouteMatch({
            path: [ERoutePatterns.MARKETPLACE, ERoutePatterns.MARKETPLACE_FUNDS],
          }),
          to: ERoutePatterns.MARKETPLACE,
          id: 'connect-marketplace-item',
          hide: blockHub || (!isDealFlowLaunchUser && !isCustomer && !isMarketplaceOnlyUser),
        },
        {
          name: 'Directory',
          icon: <DirectoryIcon width="16px" height="16px" />,
          active: !!useRouteMatch({ path: ERoutePatterns.MARKETPLACE_DIRECTORY }),
          external: true,
          onClick: async () => {
            await createEvent([
              {
                eventName: EVENT_NAME.NAVBAR_NAVIGATION,
                action: ACTION.CLICK,
                timestamp: new Date().toISOString(),
                userId: user.id,
                object: 'Navbar',
                target: [
                  { id: 'end', object: ERoutePatterns.MARKETPLACE_V2_DIRECTORY },
                  { id: 'initial', object: location.pathname },
                ],
              },
            ]);
            user.setLoadingState(true);
          },
          href: ERoutePatterns.MARKETPLACE_V2_DIRECTORY,
          to: ERoutePatterns.MARKETPLACE_V2_DIRECTORY,
          id: 'connect-directory-item',
          hide:
            blockHub ||
            (!isDealFlowLaunchUser && !isCustomer && !isMarketplaceOnlyUser) ||
            user.isImpersonated,
        },
        {
          name: 'Capital Intro',
          icon: <IncreaseMoneyIcon width="16px" height="16px" />,
          active: !!useRouteMatch({ path: ERoutePatterns.MARKETPLACE_FUNDS }),
          to: ERoutePatterns.MARKETPLACE_FUNDS,
          id: 'connect-funds-item',
          // Hidden until more definitions
          hide: true,
        },
        {
          name: 'Fee Guide',
          icon: <GrowthGraphIcon width="16px" height="16px" />,
          active: !!useRouteMatch({ path: ERoutePatterns.MARKETPLACE_FEE_GUIDE }),
          to: ERoutePatterns.MARKETPLACE_FEE_GUIDE,
          id: 'connect-fee-guide-item',
          hide: blockHub || (!isDealFlowLaunchUser && !isCustomer && !isMarketplaceOnlyUser),
        },
        {
          name: 'Conversations',
          icon: <ChatConversationIcon width="16px" height="16px" />,
          active: !!useRouteMatch({ path: ERoutePatterns.MARKETPLACE_CONVERSATIONS }),
          to: ERoutePatterns.MARKETPLACE_CONVERSATIONS,
          id: 'connect-conversations-item',
          hasNotification: hasConversations,
          hide: isDealFlowLaunchUser || blockHub || (!isCustomer && !isMarketplaceOnlyUser),
        },
        {
          name: 'Document Bank',
          icon: <ClipboardIcon width="16px" height="16px" />,
          active: !!useRouteMatch({ path: ERoutePatterns.DOCUMENT_BANK }),
          id: 'workflow-document-bank-item',
          to: ERoutePatterns.DOCUMENT_BANK,
          hide:
            blockHub ||
            loadingU4 ||
            !(isCustomer || isDealFlowLaunchUser || isMarketplaceOnlyUser) ||
            user.isImpersonated ||
            isHubUser,
        },
        {
          name: 'My Account',
          icon: <UserIcon width="16px" height="16px" />,
          active: !!useRouteMatch({
            path: ERoutePatterns.MY_ACCOUNT,
          }),
          to: ERoutePatterns.MY_ACCOUNT_SETTINGS,
          id: 'my-account-item',
          hide: !MyAccountFF || isHubUser,
        },
      ],
    },
  ];
  const links: SidebarLinks[] = [
    {
      name: 'Refer a colleague',
      route: ERoutePatterns.REFERRAL_PROGRAM,
      icon: <MegaphoneIcon width="16px" height="16px" />,
      hide: (!isCustomer || isDealFlowLaunchUser) && !isMarketplaceOnlyUser,
    },
    {
      name: 'Insights',
      route: ERoutePatterns.INSIGHTS,
      icon: <JewelIcon width="16px" height="16px" />,
      hide: !isDealFlowLaunchUser && !isCustomer && !isMarketplaceOnlyUser,
    },
    {
      name: 'Help Center',
      route: ERoutePatterns.HELP_CENTER,
      icon: <QuestionMarkIcon width="16px" height="16px" />,
      hide: true,
    },
  ];

  const firmName = user.currentFirm && user.currentFirm.name ? user.currentFirm.name : '';
  const isLoading =
    user.loadingProfileData ||
    isRedirecting ||
    loadingU4 ||
    user.isUserLoading ||
    user.loadingState;

  return (
    <>
      <MainContainer>
        {location.pathname !== ERoutePatterns.ONBOARDING && (
          <Sidebar
            items={sectionsItems}
            firmName={firmName}
            firmLogo={get(user, 'currentFirm.logo', undefined)}
            links={links}
            isLoading={isLoading}
          />
        )}
        {location.pathname.includes(ERoutePatterns.VDR) && (
          <DealContentSidebar setShowSettingsModal={show} />
        )}
      </MainContainer>

      <SettingsModal
        showVdrPermissions={user.permissions.vdr.createFileRoom}
        show={modalState.show}
        setShow={hide}
      />
    </>
  );
};

export default SidebarContainer;
