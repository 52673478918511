import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM, CREATE_NOTE } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { DealsContext } from 'app/compliance/context/DealsContext';
import { FormsContext } from 'app/compliance/context/FormsContext';
import { FormikStub, useForm } from 'app/utils/hooks/useForm';

import { validationSchema } from './InvoiceFormValidation';
import { buildINVForm, INVOICE_VALUE_INFERENCE_MAP } from './InvoiceFormHelpers';
import { mapFormData } from 'app/utils/helpers/formDataMappers';

export type InvoiceForm = {
  invoiceFiles: File[];
  breakdown: string;
  note: string;
  invoiceType: { label: string; value: string } | null;
  finalInvoice: boolean | undefined;
};

export interface UseInvoiceFormHandler {
  form: FormikStub<InvoiceForm>;
  onSubmitInvoiceForm: () => Promise<void>;
  submitting: boolean;
  modal: ReturnType<typeof useStateModal>;
}

const initialValues = {
  invoiceType: null,
  finalInvoice: undefined,
  invoiceFiles: [],
  breakdown: '',
  note: '',
};

export const InvoiceHandlerContext = React.createContext<UseInvoiceFormHandler>(
  {} as UseInvoiceFormHandler
);
export const InvoiceHandlerProvider = InvoiceHandlerContext.Provider;

export const useInvoiceFormHandler = (): UseInvoiceFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const { refetchAll, getFormQuery, updateFormMutation } = useContext(FormsContext);
  const modal = useStateModal('invoiceForm');
  const {
    hide,
    modalState: { props, show },
  } = modal;

  const form = useForm<InvoiceForm>({
    initialValues,
    validationSchema,
  });

  const onSuccess = () => {
    hide();
    form.resetForm();
    refetchAll(['complianceTracker', 'dealRegulation']);
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const [createNote, { loading: isNoteSubmitting }] = useMutation(CREATE_NOTE);

  const [getForm, { data: formData, loading: formDataLoading }] = getFormQuery;
  const [updateForm, { loading: updatingForm }] = updateFormMutation;

  const isEdit = props && props.isEdit;
  const formId = props && props.formId;

  const getFormData = async () => {
    if (formId) {
      await getForm({ variables: { id: formId } });
    }
  };

  useEffect(() => {
    if (show && isEdit) {
      getFormData();
    }
  }, [show]);

  useEffect(() => {
    if (formData && formData.form) {
      const mappedValues = mapFormData<InvoiceForm>(
        formData.form.content,
        initialValues,
        INVOICE_VALUE_INFERENCE_MAP
      );
      form.setValues(mappedValues);
    }
  }, [formData]);

  const submitting = loadingForm || formDataLoading || updatingForm || isNoteSubmitting;

  const onSubmitInvoiceForm = async (): Promise<void> => {
    const { values } = form;
    if (isEdit) {
      try {
        await updateForm({
          variables: {
            id: formId,
            content: buildINVForm(values, dealId).content,
          },
        });
        if (values.note || (values.invoiceFiles && values.invoiceFiles.length)) {
          await createNote({
            variables: {
              input: {
                type: 'NOTE',
                content: values.note,
                entity: 'FORM',
                entityId: formId,
                attachments: values.invoiceFiles,
                private: false,
              },
            },
          });
        }
        showSuccessToast({ title: 'Success!', description: 'Form was updated successfully' });
        onSuccess();
      } catch (e) {
        showErrorToast({
          title: 'Error',
          description: 'An error occurred while updating the form',
        });
      }
    } else {
      await createFormMutation({
        variables: buildINVForm(values, dealId),
      });
    }
  };

  return { form, onSubmitInvoiceForm, modal, submitting };
};
