import { FormType } from 'types/graphql-types';
import { PositionMap } from 'app/utils/helpers/formDataMappers';
import { FINAL_INVOICE_OPTIONS, INVLabels, INVOICE_TYPE_OPTIONS } from './InvoiceFormConstants';
import { InvoiceForm } from './InvoiceFormHandler';

export const buildINVForm = (props: InvoiceForm, dealId: string) => {
  const { invoiceFiles, breakdown, note, invoiceType, finalInvoice } = props;
  const mapYesNoToString = (value: boolean | undefined): string =>
    value ? FINAL_INVOICE_OPTIONS[0].label : FINAL_INVOICE_OPTIONS[1].label;
  const buildInvoiceType = (invoiceType: { label: string; value: string } | null) =>
    invoiceType && invoiceType.value;

  const buildContent = () => {
    const baseContent = [
      {
        question: INVLabels.INVOICE_TYPE,
        input: buildInvoiceType(invoiceType),
        key: 'invoiceType',
        value: JSON.stringify(invoiceType),
      },
      {
        question: INVLabels.BREAKDOWN_QUESTION,
        input: breakdown,
        key: 'breakdown',
        value: JSON.stringify(breakdown),
      },
    ];

    if (invoiceType && invoiceType.value === INVOICE_TYPE_OPTIONS[0].value) {
      baseContent.push({
        question: INVLabels.FINAL_INVOICE_QUESTION,
        input: mapYesNoToString(finalInvoice),
        key: 'finalInvoice',
        value: JSON.stringify(finalInvoice),
      });
    }

    return baseContent;
  };

  const variables = {
    type: FormType.INV,
    dealId,
    content: buildContent(),
    note,
    files: invoiceFiles,
  };

  return variables;
};

export const INVOICE_VALUE_INFERENCE_MAP: PositionMap = {
  breakdown: { match: INVLabels.BREAKDOWN_QUESTION },
};
